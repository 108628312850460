import React from 'react';
import classNames from 'classnames';

import { Card, CardTitle, CardSubtitle, CardBody } from 'reactstrap';

import Avatar from '../Avatar';

const UserCard = ({
  title,
  subtitle,
  className,
  children,
  ...restProps
}) => {
  const classes = classNames('bg-gradient-theme', className);

  return (
    <Card inverse className={classes} {...restProps}>
      <CardBody className="d-flex justify-content-center align-items-center flex-column">

        <Avatar size="g" letter={subtitle.charAt(0)} />
        
        <CardTitle>{title}</CardTitle>
        <CardSubtitle>{subtitle}</CardSubtitle>
      </CardBody>
      {children}
    </Card>
  );
};

export default UserCard;
