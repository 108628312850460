const initialState = {
    user: [],
    userLogged: false
};

export default function product(state = initialState, action) {
    switch (action.type) {
        case 'ADD_USER':
            return Object.assign({}, state, {
                user: action.user,
                userLogged: true,
            });
        case 'DELETE_USER':
            return Object.assign({}, state, {
                user: '',
                userLogged: false,
            });
        default:
            return state;
    }
}
