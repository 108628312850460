import axios from "axios";
import {config} from "../constants/config";

export const doLoginAdmin = async (params) => {

    var headers = {
        headers: { 'Content-Type': 'application/json' }
    };
    try {
        const response = await axios.post(`${config.CMS}/administradores/validacao/login`, params, headers);
        if (response.status) {
            return { success: true, payload: response.data };
        }
        return { success: false, message: 'Ocorreu um erro interno!' };
    } catch (error) {
        console.log(error)
        return { success: false, message: 'Ocorreu um erro interno!' };
    }
};
export async function listarAdministradores() {
    try {
        const response = await axios.get(`${config.CMS}/administradores/functions/readAll`);
        if (response.status) {
            return { success: true, payload: response.data.data.administradores };
        }
        return { success: false, message: 'Ocorreu um erro interno!' };
    } catch (error) {
        return { success: false, message: 'Ocorreu um erro interno!' };
    }
};
export const generalInfo = async () => {
    try {
        const response = await axios.get(`${config.CMS}/administradores/functions/generalInfo`);
        if (response.status) {
            return { success: true, payload: response.data};
        }
        return { success: false, message: 'Ocorreu um erro interno!' };
    } catch (error) {
        return { success: false, message: 'Ocorreu um erro interno!' };
    }
};

export const createAdministrador = async (params) => {
    var headers = {
        headers: { 'Content-Type': 'application/json' }
    };
    try {
        const response = await axios.post(`${config.CMS}/administradores/crud/create`, params, headers);
        if (response.status) {
            return { success: true, payload: response.data };
        }
        return { success: false, message: 'Ocorreu um erro interno!' };
    } catch (error) {
        console.log(error)
        return { success: false, message: 'Ocorreu um erro interno2!' };
    }
};
export const readAdministrador = async (param) => {
    try {
        const response = await axios.get(`${config.CMS}/administradores/crud/read/${param}`);     
        if(response.status){
            return {success: true, payload: response.data.data.administrador}; 
        }
        return {success: false, message: 'Ocorreu um erro interno!'}; 
    } catch (error) {
        return {success: false, message: 'Ocorreu um erro interno!'};
    } 
};
export const updateAdministrador = async (params) => {
    var headers = {
        headers: { 'Content-Type': 'application/json' }
    };
    try {
        const response = await axios.put(`${config.CMS}/administradores/crud/update`, params, headers);
        if (response.status) {
            return { success: true, payload: response.data };
        }
        return { success: false, message: 'Ocorreu um erro interno!' };
    } catch (error) {
        console.log(error)
        return { success: false, message: 'Ocorreu um erro interno2!' };
    }
};
export const deleteAdministrador = async (param) => {
    try {
        const response = await axios.delete(`${config.CMS}/administradores/crud/delete/${param}`);     
        if(response.status){
            return {success: true, payload: response.data.data}; 
        }
        return {success: false, message: 'Ocorreu um erro interno!'}; 
    } catch (error) {
        return {success: false, message: 'Ocorreu um erro interno!'};
    } 
};

export const validacaoEmail = async (param) => {
    try {
        const response = await axios.get(`${config.API}/advogados/validacao/email/${param}`);     
        if(response.status){
            console.log('response email', response);
            return {success: true, payload: response.data}; 
        }
        return {success: false, message: 'Ocorreu um erro interno!'}; 
    } catch (error) {
        return {success: false, message: 'Ocorreu um erro interno!'};
    } 
};
export const validacaoCPF = async (param) => {
    try {
        const response = await axios.get(`${config.API}/advogados/validacao/cpf/${param}`);     
        if(response.status){
            console.log('response cpf', response);
            return {success: true, payload: response.data}; 
        }
        return {success: false, message: 'Ocorreu um erro interno!'}; 
    } catch (error) {
        return {success: false, message: 'Ocorreu um erro interno!'};
    } 
};