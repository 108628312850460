import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import React from 'react';
import { Col, Row, Form, FormGroup, Input, Button, Label, Card, Spinner } from 'reactstrap';
import { loginAdmin } from '../actions/auth';
import logoImg from '../assets/img/logo/logo_light.png';
import bgLogin from '../assets/img/bg_login.jpg';


class AuthPage extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            login: '',
            senha: '',
			is_loading: '',
			error_message: ''
        };
	}
	handleSubmit = e => {
		e.preventDefault();
		this.doLogin();
	}
	doLogin = async () => {
        this.setState({is_loading: true, error_message: ''});
		await this.props.loginAdmin({login: this.state.login, senha: this.state.senha})
		.then((res) => {
			if (res.payload.success) {
				this.props.history.push("/")
			} else {
				this.setState({is_loading: false, error_message: "Login ou Senha incorretos."});
			}
		})
	};
	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}
	

	render() {
		return (
			<Row
				style={{
					height: '100vh',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundImage: `url(${bgLogin})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center'
				}}>
				<Col md={6} lg={4} xl={3} className="text-center">
				<img
								src={logoImg}
								className="rounded"
								style={{ width: 220, marginBottom: 20 }}
								alt="logo"
								/>
				<Card body>
						<Form onSubmit={this.handleSubmit}>
							<div className="text-center pb-4">
								
								<h3>Administração</h3>
							</div>

							<FormGroup>
								<Label for='login'>E-mail</Label>
								<Input
								type="text"
								name="login"
								value={this.state.login}
								onChange={this.handleChange}
								disabled={this.state.is_loading_btn}
								/>
							</FormGroup>
							<FormGroup>
								<Label for='senha'>Senha</Label>
								<Input
								type="password"
								name="senha"
								value={this.state.senha}
								onChange={this.handleChange}
								disabled={this.state.is_loading_btn}
								/>
							</FormGroup>

							<hr />
							<Button
								size="lg"
								className="bg-gradient-theme-left border-0"
								block
								type="submit"
								onClick={this.handleSubmit}
								style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
							>
								<span>Entrar</span>
								{this.state.is_loading && <Spinner style={{marginLeft: 10}} size="sm" color="light" />}
							</Button>
							<p style={{lineHeight: 2, marginTop: 20, backgroundColor: '#ffd5d5'}}>{this.state.error_message}</p>
						</Form>
					</Card>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loginAdmin
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);