import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import { MdClearAll, MdExitToApp} from 'react-icons/md';
import {
Button,ListGroup,ListGroupItem,Nav, Navbar, NavItem, NavLink, Popover, PopoverBody,
// NavbarToggler,
} from 'reactstrap';
import bn from '../../utils/bemnames';
import Avatar from '../Avatar';
import { UserCard } from '../Card';

import { deleteUser } from '../../actions/auth';

const bem = bn.create('header');

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isOpenUserCardPopover: false,
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  render() {

    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>

        <Nav navbar className={bem.e('nav-right')}>
          <NavItem>
            <NavLink id="Popover2">
              <Avatar
                onClick={this.toggleUserCardPopover}
                className="can-click"
                letter={(this.props.user.login).charAt(0)}
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light">
                <UserCard
                  title="Logado como:"
                  subtitle={this.props.user.login}
                  className="border-light"
                >
                  <ListGroup flush>
                    <ListGroupItem tag="button" action className="border-light" onClick={() => this.props.deleteUser()}>
                      <MdExitToApp /> Sair
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

const mapStateToProps = state => ({
  user : state.auth.user
});

const mapDispatchToProps = dispatch => bindActionCreators({
  deleteUser
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Header);
