import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware , compose } from 'redux';
import thunk from 'redux-thunk';
import { PersistGate } from 'redux-persist/integration/react'
import {persistStore, persistReducer} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { ApolloProvider } from "react-apollo";
import ApolloClient from 'apollo-boost';

import {config} from "./constants/config";
import rootReducer from './reducers';
import App from './App';
import PageSpinner from './components/PageSpinner';

const middleware = [thunk];

//persist
const client = new ApolloClient({
    uri: config.GRAPHQL,
});
const persistConfig = {
    key: 'root', 
    keyPrefix: '',
    storage,
    whitelist: ['auth'],
    autoMergeLevel2,
    timeout: null,
};
const pReducer = persistReducer(persistConfig, rootReducer);
//debug
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//store
const store = createStore(pReducer, composeEnhancer(applyMiddleware(...middleware)));
const persistor = persistStore(store);

ReactDOM.render(
    <Provider store={store}>
        <ApolloProvider client={client}>
            <PersistGate
                persistor={persistor}
                loading={<PageSpinner />}
            >
                <App />
            </PersistGate>
        </ApolloProvider>
    </Provider>,
    document.getElementById('root')
);
