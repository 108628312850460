import { doLoginAdmin } from "../api/admin";


export const loginAdmin = (params) => async (dispatch) => {
    try {
        const retorno = await doLoginAdmin(params);
        if (retorno.payload.success) {
            await dispatch(addUser(retorno.payload.data.profile));
            return retorno;
        } else {
            return retorno;
        }
    } catch (e) {
        console.log(e)
        return {success: false, message: 'Ocorreu um erro interno! catch'};
    }
};

export const addUser = (user) => {
    return {
        type: 'ADD_USER',
        user,
    };
};

export const deleteUser = () => {
    return {
        type: 'DELETE_USER',
    };
};