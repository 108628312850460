import GAListener from './components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from './components/Layout';
import PageSpinner from './components/PageSpinner';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './styles/reduction.scss';

import AuthPage from './cms_pages/AuthPage';

const DashboardPage =  React.lazy(() => import('./cms_pages/DashboardPage'));

const ClientesPage = React.lazy(() => import('./cms_pages/Clientes'));
const AdvogadosPage = React.lazy(() => import('./cms_pages/Advogados'));
const ConsultoriasPage = React.lazy(() => import('./cms_pages/Consultorias'));
const EspecialidadesPage = React.lazy(() => import('./cms_pages/Especialidades'));
const AdministradoresPage = React.lazy(() => import('./cms_pages/Administradores'));

const ClienteSingle = React.lazy(() => import('./cms_pages/ClienteSingle'));
const AdvogadoSingle = React.lazy(() => import('./cms_pages/AdvogadoSingle'));
const ConsultoriaSingle = React.lazy(() => import('./cms_pages/ConsultoriaSingle'));
const EspecialidadeSingle = React.lazy(() => import('./cms_pages/EspecialidadeSingle'));
const AdministradoresSingle = React.lazy(() => import('./cms_pages/AdministradoresSingle'));


const ConfigconteudoPage = React.lazy(() => import('./cms_pages/ConfigConteudo'));


//const getBasename = () => {
//	return `/${process.env.PUBLIC_URL.split('/').pop()}`;
//};


class App extends React.Component {
	render() {
		return (
			<BrowserRouter basename={''}>
				<GAListener>
					<Switch>
						<LayoutRoute
							exact
							path="/login"
							layout={EmptyLayout}
							component={props => (
								<AuthPage {...props} />
							)}
						/>
						{ this.props.isAuth ?
						<MainLayout breakpoint={this.props.breakpoint}>
							<React.Suspense fallback={<PageSpinner />}>
								
								<Route exact path="/" component={DashboardPage} />

								<Route exact path="/clientes" component={ClientesPage} />
								<Route exact path="/advogados" component={AdvogadosPage} />
								<Route exact path="/consultorias" component={ConsultoriasPage} />
								<Route exact path="/especialidades" component={EspecialidadesPage} />
								<Route exact path="/administradores" component={AdministradoresPage} />

								<Route exact path="/conteudo" component={ConfigconteudoPage} />

								<Route path="/cliente/:id" component={ClienteSingle} />
								<Route path="/advogado/:id" component={AdvogadoSingle} />
								<Route path="/consultoria/:id" component={ConsultoriaSingle} />
								<Route path="/especialidade/:id" component={EspecialidadeSingle} />
								<Route path="/administrador/:id" component={AdministradoresSingle} />
							</React.Suspense>
						</MainLayout>
						:
						<Redirect to="/login/" />
						}
					</Switch>
				</GAListener>
			</BrowserRouter>
		);
	}
}

const query = ({ width }) => {
	if (width < 575) {
		return { breakpoint: 'xs' };
	}

	if (576 < width && width < 767) {
		return { breakpoint: 'sm' };
	}

	if (768 < width && width < 991) {
		return { breakpoint: 'md' };
	}

	if (992 < width && width < 1199) {
		return { breakpoint: 'lg' };
	}

	if (width > 1200) {
		return { breakpoint: 'xl' };
	}

	return { breakpoint: 'xs' };
};

let AppWithMediaQueries = componentQueries(query)(App);

const mapStateToProps = state => ({
	isAuth : state.auth.userLogged
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AppWithMediaQueries);