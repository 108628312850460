import logo200Image from '../../assets/img/logo/logo_light.png';
import sidebarBgImage from '../../assets/img/sidebar/sidebar-4.jpg';
import React from 'react';
import {connect} from 'react-redux';
import { MdDashboard, MdChatBubble } from 'react-icons/md';
import { FaBalanceScale, FaUser, FaCogs, FaFileAlt, FaUserLock, FaUsersCog }from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { Nav, Navbar, NavItem, NavLink as BSNavLink, } from 'reactstrap';
import bn from '../../utils/bemnames';

const sidebarBackground = { backgroundColor: 'blue', backgroundSize: 'cover', backgroundRepeat: 'no-repeat',};

const navBasic = [
  { to: '/', name: 'Dashboard', exact: true, Icon: MdDashboard }
];
const navReports = [
  { to: '/clientes', name: 'Clientes', exact: true, Icon: FaUser },
  { to: '/advogados', name: 'Advogados', exact: true, Icon: FaBalanceScale },
  { to: '/consultorias', name: 'Consultorias', exact: true, Icon: MdChatBubble },
];
const navCMS = [
  { to: '/especialidades', name: 'Configurações', exact: true, Icon: FaCogs },
  { to: '/conteudo', name: 'Conteúdo', exact: true, Icon: FaFileAlt}
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
    console.log(this.state.isOpenPages)
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <div className="navbar-brand d-flex">
              <img
                src={logo200Image}
                style={{maxWidth: '100%'}}
                className="pr-2"
                alt=""
              />
            </div>
          </Navbar>
          <Nav vertical>
            {navBasic.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>
          <hr />
          <Nav vertical>
            {navReports.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>
          <hr />
          <Nav vertical>
            {navCMS.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>
          <hr />
            <Nav vertical>
              {(this.props.user.id === 1) &&
              <NavItem key={1} className={bem.e('nav-item')}>
                <BSNavLink
                  id="navItem-Administradores-1"
                  tag={NavLink}
                  to={'/administradores'}
                  activeClassName="active"
                  exact={true}
                >
                  <FaUsersCog className={bem.e('nav-item-icon')} />
                  <span className="">Administradores</span>
                </BSNavLink>
              </NavItem>
              }
              <NavItem key={2} className={bem.e('nav-item')}>
                <BSNavLink
                  id="navItem-SeuPerfil-2"
                  tag={NavLink}
                  to={`/administrador/${this.props.user.id}`}
                  activeClassName="active"
                  exact={true}
                >
                  <FaUserLock className={bem.e('nav-item-icon')} />
                  <span className="">Seu Perfil</span>
                </BSNavLink>
              </NavItem>
            </Nav>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = state => ({
  user : state.auth.user
});

export default connect(mapStateToProps)(Sidebar);
