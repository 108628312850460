import React from 'react';

const Avatar = ({letter, size, className}) => {
  let letterSize = size === 'g' ? 28 : 16;
  let avatarSize = size === 'g' ? 50 : 30;
  return (
    <div className={className} style={{background: '#AA8211', width: avatarSize, height: avatarSize, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <span style={{color: '#fff', fontSize: letterSize, fontWeight: 700, textTransform: 'uppercase'}}>{letter}</span>
    </div>
  );
};

export default Avatar;